import lozad from 'lozad';
lozad().observe();
import './datalayer.js';
import 'bootstrap/dist/css/bootstrap.min.css';
// import '../../themes/owlcarousel/owl.carousel.min.css'
import '../../themes/login.css'
import '../../themes/slick.css'
import '../../themes/mobile/menu.css'
import '../../themes/suporte.css'
import '../../themes/ecommerce.css'
import '../../themes/footer/1/footer.css'
import '../../themes/rating/theme.css'
import '../../themes/rating/star-rating.css'
import '../../themes/custom.css'

import './main.js';
import '../../themes/header/1/header.css'
import '../../themes/mobile/header.css'

// Botstrap
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
