import { DataLayerEvents } from './data-layer-events.js';
import { DataLayerHandler } from './data-layer-handler.js';
import { Events, dispatchViewCartEvent } from '../events.js';
import { getPage, pageReloaded } from '../utilities.js';


/**
 * Eventos do datalayer relacionados ao product thumb.
 */
export const setProductThumbEvents = () => {
  const thumbs = document.querySelectorAll(".product-thumb");
  const itemList = [];
  thumbs?.forEach(thumb => {
    const productData = DataLayerHandler.getProductData({ element: thumb });
    if (productData) {
      const itemListName = thumb.closest('[data-listName]')?.getAttribute('data-listName')
      const itemIndex = thumb.getAttribute('data-index');
      productData.index = parseInt(itemIndex);
      if (itemListName) productData.item_list_name = itemListName;
      itemList.push(Object.assign({}, productData));
      thumb.addEventListener("click", () => DataLayerEvents.selectItem(productData));
    }
  });
  if (itemList.length > 0) {
    DataLayerEvents.viewItemList(itemList);
  }
}

/**
 * Eventos do data layer que acontecem na página do produto.
 */
export const setProductPageEvents = () => {
  document.addEventListener(Events.ADD_TO_CART, e => {
    const { pid, amount } = e.detail;
    const productData = DataLayerHandler.getProductData({ id: pid });
    productData.quantity = amount;
    DataLayerEvents.addToCart(productData);
  });
  const product = document.querySelector(".product-info #form-product-buy");
  if (!product) return;
  const productData = DataLayerHandler.getProductData({ element: product });
  if (!productData) return;
  DataLayerEvents.productDetail(productData);
}

/**
 * Eventos do data layer que acontecem no carrinho de compras.
 */
export const setPurchaseListEvents = () => {
  document.addEventListener(Events.REMOVE_FROM_CART, e => {
    if (e.detail.list_id) {
      const listItem = document.querySelector(`#form-purchase-list [data-purchaselist='${e.detail.list_id}']`) 
        ?? document.querySelector(`[data-purchaselist='${e.detail.list_id}']`);
      const productData = DataLayerHandler.getProductData({ element: listItem });
      if (productData) {
        const { price, item_variant, quantity } = DataLayerHandler.getDataFromHtml(listItem);
        productData.item_variant = item_variant;
        productData.price = !isNaN(price) ? parseFloat(price) : parseFloat(productData.price);
        productData.quantity = !isNaN(quantity) ? parseFloat(quantity) : 1;
        DataLayerEvents.removeFromCart(productData);
      }
    }
  });

  document.addEventListener(Events.VIEW_CART, _e => {
    const cartProducts = DataLayerHandler.getProductsDataFromPurchaseList();
    if (cartProducts.length > 0) DataLayerEvents.viewCart(cartProducts);
  });

  if (getPage() === 'purchase-list' && !pageReloaded()) {
    dispatchViewCartEvent();
  }
}

export const setCheckoutEvents = () => {
  if (getPage() !== 'checkout') return;
  const productData = DataLayerHandler.getProductsDataFromPurchaseList();
  if (!productData) return;

  if (!pageReloaded()) {
    DataLayerEvents.beginCheckout(productData);
  }
  
  document.addEventListener(Events.ADD_SHIPPING_INFO, e => {
    DataLayerEvents.addShippingInfo(productData, e.detail.shipping, e.detail.value);
  });

  document.addEventListener(Events.ADD_PAYMENT_INFO, e => {
    DataLayerEvents.addPaymentInfo(productData, e.detail.paymentName, e.detail.value);
  });
}

/**
 * Evento de compra sucedida.
 */
export const setPurchaseEvent = () => {
  if (getPage() !== 'purchase-order') return;

  const productsData = DataLayerHandler.getProductsDataFromPurchaseList();
  const tracked = document.querySelector('[data-tracked]')?.getAttribute('data-tracked') == 1;
  
  if (!productsData || tracked) return;

  DataLayerEvents.purchase(productsData, DataLayerHandler.getPurchaseDetails());
  DataLayerHandler.updateTracked();
}