export const setUserData = () => {
  const data = document.querySelector("script#personData")?.textContent;
  if (data) {
    const personData = JSON.parse(data);
    const personDataLayer = {
      id: personData.person_id,
      email: personData.email,
      first_name: personData.first_name,
      last_name: personData.last_name,
      address: `${personData.endereco}, ${personData.numero}`,
      city: personData.cidade,
      uf: personData.estado,
      country: 'BR',
      zipcode: personData.cep,
      n_pedidos: personData.n_pedidos,
    };
    const telefones = personData.telefone.split(',');
    if (telefones.length > 0) {
      telefones.forEach((e,i) => i === 0 ? personDataLayer['phone'] = e : personDataLayer['phone'+(i+1)] = e);
    }
    window.dataLayer.push(personDataLayer);
  }
}
