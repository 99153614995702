import { getPage } from '../utilities';
/**
 * 
 * Responsavel por cuidar dos eventos do data Layer
 * 
 */
export class DataLayerEvents {
  
  /**
   * Define o array dataLayer caso ele não exista.
   */
  static init() {
    window.dataLayer = window.dataLayer || [];
  }
    /**
   * Event dispara quando click na thumb de algum produto
   */
  static selectItem(data) {
    this.filterData(data);
    this.clear();
    window.dataLayer.push({
      ecomm_pagetype: getPage(),
      event: 'select_item',
      ecommerce: {
        items: data,
			},
    });
  }
  /**
   * Evento dispara quando um produto na thumb está visível completamente ná página
   */
  static viewItemList(data) {
    this.filterData(data);
    this.clear();
    window.dataLayer.push({
      ecomm_pagetype: getPage(),
      event: 'view_item_list',
      ecommerce: {
				items: data,
			},
    });
  }
  /**
   * Evento dispara quando é carragado os detalhes de um produto único
   */
  static productDetail(data) {
    this.filterData(data);
    this.clear();
    window.dataLayer.push({
      ecomm_pagetype: getPage(),
      event: 'view_item',
      ecommerce: {
        items: data,
			},
    });
  }

  /**
   * Evento dispara quando produto é adicionado ao carrinho
   */
   static viewCart(data) {
    this.filterData(data);
    this.clear();
    window.dataLayer.push({
      ecomm_pagetype: getPage(),
      event: 'view_cart',
      ecommerce: {
				items: data,
			},
    });
  }

  /**
   * Evento dispara quando produto é adicionado ao carrinho
   */
  static addToCart(data) {
    this.filterData(data);
    this.clear();
    window.dataLayer.push({
      ecomm_pagetype: getPage(),
      event: 'add_to_cart',
      ecommerce: {
				items: data,
			},
    });
  }
  /**
   * Evento dispara quando um produto é removido do carrinho
   */
  static removeFromCart(data) {
    this.filterData(data);
    this.clear();
    window.dataLayer.push({
      ecomm_pagetype: getPage(),
      event: 'remove_from_cart',
      ecommerce: {
				items: data,
			},
    });
  }

  /**
   * Evento dispara quando é iniciado o checkout (Quando é carregado a pagina /finalizar-compra/)
   */
   static beginCheckout(data) {
    this.filterData(data);
    this.clear();
    window.dataLayer.push({
      ecomm_pagetype: getPage(),
      event: 'begin_checkout',
      ecommerce: {
				items: data,
			},
    });
  }

  /**
   * Evento dispara quando é adicionado um forma de envio no checkout
   */
   static addShippingInfo(data, shipping_tier, value) {
    this.filterData(data);
    this.clear();
    window.dataLayer.push({
      ecomm_pagetype: getPage(),
      event: 'add_shipping_info',
      ecommerce: {
        currency: 'BRL',
        value,
        shipping_tier,
				items: data,
			},
    });
  }
  
  /**
   * Evento dispara quando é feita a compra com uma forma de pagamento
   */
   static addPaymentInfo(data, payment_type, value) {
    this.filterData(data);
    this.clear();
    window.dataLayer.push({
      ecomm_pagetype: getPage(),
      event: 'add_payment_info',
      ecommerce: {
        currency: 'BRL',
        value,
        payment_type,
				items: data,
			},
    });
  }

  /**
   * Evento é disparado quando é finalizado uma compra (Possivelmente no primeiro carregamento da página do pedido).
   */
  static purchase(data, transactionDetails) {
    this.filterData(data);
    this.clear();
    window.dataLayer.push({
      ecomm_pagetype: getPage(),
      event: 'purchase',
      ecommerce: {
        transaction_id: parseInt(transactionDetails.transaction_id),
        affiliation: data[0] && data[0].affiliation,
        value: +parseFloat(transactionDetails.revenue).toFixed(2),
        tax: +parseFloat(transactionDetails.tax).toFixed(2),
        shipping: +parseFloat(transactionDetails.shipping).toFixed(2),
        currency: "BRL",
        coupon: transactionDetails.coupon,
				items: data,
			},
    }); 
  }

  /**
   * Adiciona informações extras para os dados do DataLayer.
   */
  static filterData(data) {
    if (data.length !== undefined) {
      data.forEach(d => {
        d.id = d.item_id;
        d.google_business_vertical = 'retail';
      });
      return;
    }
    
    data.id = data.item_id;
    data.google_business_vertical = 'retail';
  }

  /**
   * Limpa todos os elementos datalayer que tem a proprieda passada
   */
  static clear() {
    window.dataLayer.push({ ecommerce: null });
  }

  /**
   * Retorna o index de um evento no array data layer
   */
  static getEventIndex(eventType) {
    return window.dataLayer.findIndex(e => e.event && e.event === eventType);
  }
}

/**
 * Classe que cria uma fila para enviar conteudo ao data layer em batches;
 */
class DataLayerQueue {
  static data = [];
  static batch = 20;
  static layerEvent = "";
  /**
   * Inicia um envento que que envia a data da fila que ainda não foi enviada.
   */
  static bind() {
    window.addEventListener('beforeunload', () => this.send());
  }

  /**
   * Adiciona data na fila, e se tiver no pacoto de fila o valor de batch envia a lista da fila
   */
  static add(data) {
    this.data.push(data);
    if (this.data.length === this.batch) this.send();
  }
  /**
   * Remove um uma data da fila pelo seu index ou id.
   */
  static remove({ index, productId }) {
    if (index && !isNaN(index) && this.data[index]) {
      return this.data.splice(index, 1);
    }
    const foundIndex = this.data.findIndex(i => i.id === productId);
    return foundIndex !== -1 && this.data.splice(foundIndex, 1);
  }
  /**
   * Retorna a data que está na fila
   */
  static get() {
    return this.data;
  }
  /**
   * Envia os produtos na fila de impressões
   */
  static send() {
    if (this.data.length > 0 && typeof DataLayerEvents[this.layerEvent] === 'function') {
      DataLayerEvents[this.layerEvent](this.data);
      this.data = [];
    }
  }
}

export class ImpressionQueue extends DataLayerQueue {
  static layerEvent = 'viewItemList';
}
